import React from 'react';

interface CustomIconProps {
  filled?: boolean;
  color?: string;
}

const ExcessesIcon: React.FC<CustomIconProps> = () => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M12.9969 1.10547H6.30188C4.95088 1.10547 3.85188 2.20447 3.85188 3.55547V8.80047H2.99688C1.64588 8.80047 0.546875 9.89947 0.546875 11.2505V12.7955C0.546875 13.9525 1.48688 14.8935 2.64488 14.8955C2.64538 14.8955 2.64588 14.896 2.64638 14.896H13.3464C14.5039 14.896 15.4464 13.954 15.4464 12.796V3.55547C15.4469 2.20447 14.3479 1.10547 12.9969 1.10547ZM1.44688 12.7955V11.2505C1.44688 10.396 2.14238 9.70047 2.99688 9.70047H3.85188V12.786C3.85188 12.789 3.85038 12.7915 3.85038 12.7945C3.85038 13.457 3.31138 13.996 2.64688 13.996C1.98538 13.9955 1.44688 13.4575 1.44688 12.7955ZM14.5469 12.7955C14.5469 13.4575 14.0089 13.9955 13.3474 13.9955H4.37338C4.61238 13.655 4.75188 13.211 4.75188 12.7955V3.55547C4.75188 2.70097 5.44738 2.00547 6.30188 2.00547H12.9969C13.8514 2.00547 14.5469 2.70097 14.5469 3.55547V12.7955Z'
      fill='white'
    />
    <path
      d='M12.4516 5.23828H6.84063C6.59213 5.23828 6.39062 5.43978 6.39062 5.68828C6.39062 5.93678 6.59213 6.13828 6.84063 6.13828H12.4516C12.7001 6.13828 12.9016 5.93678 12.9016 5.68828C12.9016 5.43978 12.7001 5.23828 12.4516 5.23828Z'
      fill='white'
    />
    <path
      d='M12.4516 7.55078H6.84063C6.59213 7.55078 6.39062 7.75228 6.39062 8.00078C6.39062 8.24928 6.59213 8.45078 6.84063 8.45078H12.4516C12.7001 8.45078 12.9016 8.24928 12.9016 8.00078C12.9016 7.75228 12.7001 7.55078 12.4516 7.55078Z'
      fill='white'
    />
    <path
      d='M9.64613 9.86328H6.84063C6.59213 9.86328 6.39062 10.0648 6.39062 10.3133C6.39062 10.5618 6.59213 10.7633 6.84063 10.7633H9.64613C9.89463 10.7633 10.0961 10.5618 10.0961 10.3133C10.0961 10.0648 9.89463 9.86328 9.64613 9.86328Z'
      fill='white'
    />
  </svg>
);

export default ExcessesIcon;
