import React from 'react';

const SecurePayment: React.FC = () => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M14.9618 3.16336L12.1046 2.0205C12.0364 1.99317 11.9603 1.99317 11.892 2.0205L9.0349 3.16336C8.98193 3.18459 8.93654 3.22119 8.90456 3.26844C8.87258 3.3157 8.85548 3.37145 8.85547 3.42851V6.38165C8.85695 6.92427 8.99815 7.45736 9.26548 7.92957C9.53281 8.40177 9.91725 8.79716 10.3818 9.07765L11.8515 9.95936C11.8958 9.98595 11.9466 9.99999 11.9983 9.99999C12.0501 9.99999 12.1008 9.98595 12.1452 9.95936L13.4269 9.19022V12.3902C13.4267 12.5144 13.3773 12.6335 13.2895 12.7214C13.2016 12.8092 13.0826 12.8586 12.9583 12.8588H1.89547C1.77124 12.8586 1.65215 12.8092 1.56431 12.7214C1.47646 12.6335 1.42705 12.5144 1.4269 12.3902V5.99993H7.99833C8.0741 5.99993 8.14678 5.96983 8.20036 5.91625C8.25394 5.86267 8.28404 5.79 8.28404 5.71422C8.28404 5.63844 8.25394 5.56577 8.20036 5.51219C8.14678 5.45861 8.0741 5.42851 7.99833 5.42851H1.4269V4.75422C1.42705 4.62999 1.47646 4.5109 1.56431 4.42306C1.65215 4.33522 1.77124 4.2858 1.89547 4.28565H7.99833C8.0741 4.28565 8.14678 4.25555 8.20036 4.20196C8.25394 4.14838 8.28404 4.07571 8.28404 3.99993C8.28404 3.92416 8.25394 3.85149 8.20036 3.7979C8.14678 3.74432 8.0741 3.71422 7.99833 3.71422H1.89547C1.61969 3.71437 1.35525 3.82399 1.16024 4.019C0.96524 4.214 0.85562 4.47844 0.855469 4.75422V12.3885C0.85562 12.6643 0.96524 12.9287 1.16024 13.1237C1.35525 13.3187 1.61969 13.4284 1.89547 13.4285H12.9583C13.2341 13.4284 13.4985 13.3187 13.6936 13.1237C13.8886 12.9287 13.9982 12.6643 13.9983 12.3885V8.79993C14.3542 8.50484 14.6411 8.13523 14.8385 7.71718C15.036 7.29913 15.1393 6.84284 15.1412 6.38051V3.42851C15.1412 3.37145 15.1241 3.3157 15.0921 3.26844C15.0601 3.22119 15.0147 3.18459 14.9618 3.16336Z'
      fill='#AEAEAE'
    />
    <path
      d='M11.0585 5.561C11.0319 5.53444 11.0004 5.51336 10.9658 5.49897C10.9311 5.48458 10.8939 5.47716 10.8564 5.47713C10.8188 5.4771 10.7817 5.48447 10.747 5.49881C10.7123 5.51316 10.6808 5.53419 10.6542 5.56072C10.6276 5.58724 10.6065 5.61874 10.5922 5.65342C10.5778 5.68809 10.5703 5.72526 10.5703 5.7628C10.5703 5.80034 10.5777 5.83752 10.592 5.87222C10.6063 5.90691 10.6274 5.93844 10.6539 5.965L11.4162 6.72672C11.4698 6.78028 11.5424 6.81037 11.6182 6.81037C11.6939 6.81037 11.7666 6.78028 11.8202 6.72672L13.3442 5.20272C13.3978 5.14907 13.4278 5.07633 13.4278 5.00052C13.4277 4.9247 13.3976 4.85201 13.3439 4.79843C13.2903 4.74486 13.2175 4.71479 13.1417 4.71484C13.0659 4.7149 12.9932 4.74507 12.9396 4.79872L11.6179 6.121L11.0585 5.561Z'
      fill='#232323'
    />
    <path
      d='M2.85603 11.1426C2.78025 11.1426 2.70758 11.1727 2.654 11.2263C2.60041 11.2798 2.57031 11.3525 2.57031 11.4283C2.57031 11.5041 2.60041 11.5767 2.654 11.6303C2.70758 11.6839 2.78025 11.714 2.85603 11.714H5.14174C5.21752 11.714 5.29019 11.6839 5.34377 11.6303C5.39735 11.5767 5.42746 11.5041 5.42746 11.4283C5.42746 11.3525 5.39735 11.2798 5.34377 11.2263C5.29019 11.1727 5.21752 11.1426 5.14174 11.1426H2.85603Z'
      fill='#AEAEAE'
    />
  </svg>
);

export default SecurePayment;
