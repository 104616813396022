import { StarFilled } from '@ant-design/icons';
import { Rate } from 'antd';
import React from 'react';

interface StarRatingProps {
  rating: number;
  color: string;
}

const StarRating: React.FC<StarRatingProps> = ({ rating, color }) => {
  const getStarIcon = (index: number): React.ReactNode => {
    const starSize = '16px'; // Adjust the size here

    if (index + 1 <= rating) {
      return <StarFilled style={{ color, fontSize: starSize }} />;
    }
    if (index + 0.5 <= rating) {
      return (
        <StarFilled
          style={{ color, fontSize: starSize, clipPath: 'inset(0 50% 0 0)' }}
        />
      );
    }
    return <StarFilled style={{ color: '#e8e8e8', fontSize: starSize }} />;
  };

  return (
    <Rate
      allowHalf
      value={rating}
      character={({ index }) => getStarIcon(index!)}
      disabled
      count={Math.ceil(rating)}
    />
  );
};

export default StarRating;
