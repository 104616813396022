import React from 'react';

export interface CustomIconProps {
  filled?: boolean;
}

const CertificateIcon: React.FC<CustomIconProps> = () => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M15.4682 8.32167V1.35717C15.4682 0.608802 14.8593 0 14.111 0H3.07592C2.32755 0 1.71875 0.608802 1.71875 1.35717V15.8296C1.71875 16.578 2.32755 17.1868 3.07592 17.1868H11.4058V19.3742C11.4058 19.8521 11.9234 20.1553 12.3409 19.9168L14.2182 18.844C16.2917 20.0289 16.1833 19.9991 16.4056 19.9991C16.7508 19.9991 17.0306 19.7189 17.0306 19.3742V15.1155C19.255 12.9785 18.3739 9.26359 15.4682 8.32167ZM3.07592 15.9368C3.01678 15.9368 2.9687 15.8887 2.9687 15.8296V1.35717C2.9687 1.29803 3.01678 1.24995 3.07592 1.24995H14.111C14.1701 1.24995 14.2182 1.29803 14.2182 1.35717V8.12465C11.9782 8.12465 10.1559 9.94699 10.1559 12.187C10.1559 13.3365 10.6359 14.3758 11.4058 15.1155V15.9368H3.07592ZM15.7806 18.2972L14.5283 17.5816C14.3361 17.4718 14.1003 17.4718 13.9081 17.5816L12.6557 18.2972V15.9366C13.6523 16.3534 14.7827 16.354 15.7806 15.9366L15.7806 18.2972ZM14.2182 14.9994C12.6675 14.9994 11.4058 13.7377 11.4058 12.187C11.4058 10.6362 12.6675 9.3746 14.2182 9.3746C15.769 9.3746 17.0306 10.6362 17.0306 12.187C17.0306 13.7377 15.769 14.9994 14.2182 14.9994Z'
        fill='#737373'
      />
      <path
        d='M13.9057 13.7891C13.6615 13.7891 13.6305 13.7313 12.6221 13.0591C12.3349 12.8676 12.2573 12.4796 12.4488 12.1924C12.6403 11.9052 13.0283 11.8276 13.3155 12.019L13.733 12.2973L14.6362 10.9424C14.8277 10.6552 15.2158 10.5776 15.5029 10.7691C15.7901 10.9606 15.8677 11.3486 15.6763 11.6358L14.4263 13.5107C14.3058 13.6913 14.1076 13.7891 13.9057 13.7891Z'
        fill='#737373'
      />
      <path
        d='M10.781 4.37495H5.46872C5.12358 4.37495 4.84375 4.09511 4.84375 3.74997C4.84375 3.40483 5.12358 3.125 5.46872 3.125H10.781C11.1262 3.125 11.406 3.40483 11.406 3.74997C11.406 4.09511 11.1261 4.37495 10.781 4.37495Z'
        fill='#737373'
      />
      <path
        d='M10.781 6.87495H5.46872C5.12358 6.87495 4.84375 6.59511 4.84375 6.24997C4.84375 5.90483 5.12358 5.625 5.46872 5.625H10.781C11.1262 5.625 11.406 5.90483 11.406 6.24997C11.406 6.59511 11.1261 6.87495 10.781 6.87495Z'
        fill='#737373'
      />
      <path
        d='M8.59359 9.37495H5.46872C5.12358 9.37495 4.84375 9.09511 4.84375 8.74997C4.84375 8.40483 5.12358 8.125 5.46872 8.125H8.59359C8.93873 8.125 9.21856 8.40483 9.21856 8.74997C9.21856 9.09511 8.93873 9.37495 8.59359 9.37495Z'
        fill='#737373'
      />
    </svg>
  );
};

export default CertificateIcon;
