import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';
import { Collapse } from 'antd';
import { FC, useState } from 'react';

import Headings from '../landingPage/heading';
import ButtonDefault, { ButtonVariants } from '../../shared/basic/button';
import { ShouldRender } from '../../shared/basic/ShouldRender';
import { ICurriculumLandingPageData } from '../../../utils/interfaces/landingPage';

const { Panel } = Collapse;

const customExpandIcon = (props: any) => {
  if (props.isActive) {
    return (
      <a className='' style={{ color: 'black' }}>
        <ChevronUpIcon className='w-4 text-white' />
      </a>
    );
  } else {
    return (
      <a style={{ color: 'black' }}>
        <ChevronDownIcon className='w-4 text-white' />
      </a>
    );
  }
};

interface CardSliderProps {
  data: ICurriculumLandingPageData;
}

const CurriculumSection: FC<CardSliderProps> = ({ data }) => {
  const [showMore, setShowMore] = useState(false);

  const chaptersToShow = showMore
    ? data?.chapters
    : data?.chapters?.slice(0, 5);

  const toggleShowMore = () => {
    setShowMore((prev) => !prev);
  };

  return (
    <div className='flex flex-col '>
      <Headings heading='CURRICULUM' />
      <span className='border border-cartBgColor rounded-lg '>
        <Collapse
          expandIconPosition='end'
          className='border-2 border-cartBgColor customBorder customCollapseBg'
          expandIcon={(props) => customExpandIcon(props)}
          accordion
        >
          {chaptersToShow?.map((section, index) => {
            return (
              <Panel
                className='customBorder customCollapseBg bg-readerLightGrayBg rounded-t-none border-cartBgColor'
                header={
                  <div className='md:flex px-2 lg:px-4 py-1 lg:py-2 gap-x-1'>
                    <span className='font-medium'>{index + 1}.</span>
                    <span> {' ' + section.name}</span>
                  </div>
                }
                key={index + 1}
              >
                <div className='list-disc'>
                  <ul className='list-disc flex flex-col text-xs gap-y-2 px-7 py-2 lg:px-8'>
                    {section?.sections
                      ?.filter((contentItem) => contentItem.trim() !== '')
                      .map((contentItem, index) => (
                        <li key={index}>{contentItem}</li>
                      ))}
                  </ul>
                </div>
              </Panel>
            );
          })}
        </Collapse>
      </span>
      <ShouldRender check={data?.chapters?.length > 5}>
        <ButtonDefault
          size={4}
          enableScaling={false}
          variant={ButtonVariants.UNSTYLED}
          className='w-full p-1 bg-readerLightGrayBg  hover:bg-[#1e1e1e] mt-4 uppercase text-xs text-white'
          onClick={toggleShowMore}
        >
          <span className='py-1'>{!showMore ? 'Show All' : 'Show less'}</span>
        </ButtonDefault>
      </ShouldRender>
    </div>
  );
};

export default CurriculumSection;
