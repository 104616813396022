import React from 'react';

interface CustomIconProps {
  filled?: boolean;
}

const YoutubeIcon: React.FC<CustomIconProps> = () => (
  <svg
    width='62'
    height='42'
    viewBox='0 0 62 42'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <rect width='61.2' height='42' rx='10' fill='#F02F1D' />
    <path
      d='M38.1016 20.734C38.7682 21.1189 38.7682 22.0811 38.1016 22.466L26.7016 29.0478C26.0349 29.4327 25.2016 28.9516 25.2016 28.1818L25.2016 15.0182C25.2016 14.2484 26.0349 13.7673 26.7016 14.1522L38.1016 20.734Z'
      fill='white'
    />
  </svg>
);

export default YoutubeIcon;
