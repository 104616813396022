import { baseForPdf } from '../apiClients';
import { fetchAndCacheWithoutPage } from '../../components/shared/CacheStroage/CacheWithoutPage';
import { Endpoints } from '../../network';

export const getBookLandingPageData = async (slug: string) => {
  const apiUrl = `${Endpoints.getLandingPageData}/${slug}`;

  const apiCall = async () => {
    const response = await baseForPdf.get(apiUrl);
    return response?.data;
  };

  return fetchAndCacheWithoutPage(apiCall, apiUrl);
};
