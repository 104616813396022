import { ClockIcon } from '@heroicons/react/outline';
import { Divider, Tag } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { motion } from 'framer-motion';
import Image from 'next/image';
import React from 'react';

import StarRating from '../StarRating';
import { ShouldRender } from '../../shared/basic/ShouldRender';
import KeyValueTag from '../../shared/keyPoints';
import BookIcon from '../../../../assets/Icons/BookIcon';
import CertificateIcon from '../../../../assets/Icons/CertificateIcon';
import FrameworkIcon from '../../../../assets/Icons/FrameworkIcon';
import GoogleTranslateIcon from '../../../../assets/Icons/google-translate';
import LevelIcon from '../../../../assets/Icons/LevelIcon';
import { IHeroSectionLandingPage } from '../../../utils/interfaces/landingPage';

interface ProductProps {
  heroSectionData?: IHeroSectionLandingPage;
}

const iconMap = {
  finishIn: <ClockIcon className='w-5 h-5 text-[#737373]' />,
  getCertificate: <CertificateIcon />,
  noOfDesignFrameworks: <FrameworkIcon />,
  language: <GoogleTranslateIcon filled={true} color='#737373' />,
  pageNumber: <BookIcon />,
  level: <LevelIcon />,
};

const formatMap = {
  finishIn: (value: string) => `Finish in ${value}`,
  getCertificate: () => 'Get a Certificate',
  noOfDesignFrameworks: (value: string) => `No. of Design Frameworks: ${value}`,
  language: (value: string) => value,
  pageNumber: (value: string) => `${value} Pages`,
  level: (value: string) => `Level: ${value}`,
};

const HeroSection: React.FC<ProductProps> = ({ heroSectionData }) => {
  const screens = useBreakpoint();

  return (
    <React.Fragment>
      <div className='text-fadedWhite  flex flex-col gap-y-2  lg:w-1/2 xl:w-3/5'>
        <div className='flex gap-y-1 md:gap-y-5 flex-col'>
          <ShouldRender check={screens?.lg}>
            <div className='flex w-min pb-1'>
              <div className='flex w-min '></div>
              <motion.div
                style={{
                  position: 'relative',
                  display: 'inline-block',
                  borderRadius: '4px',
                  padding: '2px',
                  backgroundColor: '#001330',
                }}
              >
                <motion.div
                  initial={{ backgroundPosition: '0% 50%' }}
                  animate={{ backgroundPosition: '200% 50%' }}
                  transition={{
                    ease: 'linear',
                    duration: 3,
                    repeat: Infinity,
                  }}
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    borderRadius: '4px',
                    border: '2px solid transparent',
                    background:
                      'linear-gradient(90deg, rgba(35,122,255,0) 25%, rgba(35,122,255,1) 50%, rgba(35,122,255,0) 75%)', // Blue gradient
                    backgroundSize: '200% 100%',
                    pointerEvents: 'none',
                  }}
                />
                <div
                  className='text-[#237AFF] whitespace-nowrap bg-[#001330] font-semibold py-2 px-3 text-xs flex'
                  style={{
                    borderRadius: '4px',
                    zIndex: 2,
                    position: 'relative',
                  }}
                >
                  {heroSectionData?.productHighlightText}
                </div>
              </motion.div>
            </div>
          </ShouldRender>
          <span className='text-xs font-light lg:font-normal lg:text-sm text-white leading-5  mt-2 lg:mt-0'>
            BY {heroSectionData?.authorName?.toUpperCase()}
            <span className='inline-flex items-center'>
              <span className=''>
                <Divider type='vertical' />
              </span>

              <ShouldRender check={heroSectionData?.authorName}>
                <Image
                  alt={heroSectionData?.authorName || 'company_icon'}
                  src={heroSectionData?.companyIcon as string}
                  width={screens.lg ? 16 : 14}
                  height={screens.lg ? 16 : 14}
                  className='ml-1 mr-2'
                />
              </ShouldRender>
            </span>
            {heroSectionData?.authorDesignation}
          </span>
          <div className='flex flex-col mt-2'>
            <span className='text-lg lg:text-[26px] font-semibold'>
              {heroSectionData?.productTitle}
            </span>
            <span className='text-sm md:text-base font-medium text-[#E2E2E2] mt-2 md:mt-3'>
              {heroSectionData?.shortDescription}
            </span>
          </div>
        </div>

        <div className='flex flex-col lg:items-center lg:flex-row gap-3 lg:gap-5 mt-2 '>
          <span className='flex items-center text-sm md:text-base lg:text-xl gap-x-2'>
            <span className=''>
              <StarRating
                rating={heroSectionData?.rating || 0}
                color='#2DD598'
              />
            </span>
            <span className='font-medium text-sm mt-1'>
              {heroSectionData?.rating}
            </span>
          </span>

          {/* <ShouldRender check={screens.lg}>
            <span className=''>
              <Divider type='vertical' />
            </span>
          </ShouldRender> */}

          {/* <div className='flex flex-row gap-x-5'>
            <span className='flex items-center text-sm md:text-base lg:text-xl gap-x-2'>
              <span className=' text-gray-300'>
                <GoogleTranslateIcon filled={true} />
              </span>
              <span className='font-medium text-sm'>
                {heroSectionData?.language}
              </span>
            </span>

            <span className='flex items-center text-sm md:text-base lg:text-xl gap-x-2'>
              <span className='text-gray-300'>
                <OpenedBookIcon />
              </span>
              <span className='font-medium text-sm'>
                {' '}
                {heroSectionData?.numberOfPages} Pages
              </span>
            </span>
          </div> */}
        </div>
        <ShouldRender check={heroSectionData?.keyPoints}>
          <div className='mt-5 flex flex-wrap gap-2'>
            {heroSectionData?.keyPoints?.map((keyPoint, index) => {
              return (
                <Tag
                  className='border-none rounded-lg px-3 md:px-5 py-2 m-0 bg-[#202020] text-qureosSecondary'
                  key={index}
                >
                  <KeyValueTag
                    key={index}
                    name={keyPoint.name}
                    value={keyPoint.value ?? ''}
                    iconMap={iconMap}
                    formatMap={formatMap}
                  />
                </Tag>
              );
            })}
          </div>
        </ShouldRender>
      </div>
    </React.Fragment>
  );
};
export default HeroSection;
