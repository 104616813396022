import React from 'react';

interface CustomIconProps extends React.SVGProps<SVGSVGElement> {
  width?: number;
  height?: number;
  fill?: string;
}

const Articles: React.FC<CustomIconProps> = ({
  width,
  height,
  fill,
  ...rest
}) => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <g clipRule='url(#clip0_4145_46923)'>
        <path
          d='M8 4.16406C8.27613 4.16406 8.5 4.38792 8.5 4.66406V8.1236L9.64646 6.9772C9.84173 6.78193 10.1583 6.78193 10.3535 6.9772C10.5488 7.17246 10.5488 7.489 10.3535 7.68426L8.35353 9.68426C8.2598 9.77806 8.1326 9.83073 8 9.83073C7.8674 9.83073 7.7402 9.77806 7.64646 9.68426L5.64645 7.68426C5.45118 7.489 5.45118 7.17246 5.64645 6.9772C5.84171 6.78193 6.15829 6.78193 6.35355 6.9772L7.5 8.1236V4.66406C7.5 4.38792 7.72386 4.16406 8 4.16406Z'
          fill='white'
        />
        <path
          d='M4.83594 11.332C4.83594 11.0559 5.0598 10.832 5.33594 10.832H10.6693C10.9454 10.832 11.1693 11.0559 11.1693 11.332C11.1693 11.6082 10.9454 11.832 10.6693 11.832H5.33594C5.0598 11.832 4.83594 11.6082 4.83594 11.332Z'
          fill='white'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M7.96434 0.832031C6.42542 0.832025 5.2194 0.832018 4.2784 0.958531C3.31525 1.08802 2.55524 1.35825 1.9587 1.95479C1.36216 2.55133 1.09193 3.31134 0.962438 4.2745C0.835924 5.21549 0.835931 6.42152 0.835938 7.96043V8.03696C0.835931 9.5759 0.835924 10.7819 0.962438 11.7229C1.09193 12.686 1.36216 13.4461 1.9587 14.0426C2.55524 14.6392 3.31525 14.9094 4.2784 15.0389C5.2194 15.1654 6.42542 15.1654 7.96434 15.1654H8.04087C9.5798 15.1654 10.7858 15.1654 11.7268 15.0389C12.6899 14.9094 13.45 14.6392 14.0465 14.0426C14.6431 13.4461 14.9133 12.686 15.0428 11.7229C15.1693 10.7819 15.1693 9.5759 15.1693 8.03696V7.96043C15.1693 6.42152 15.1693 5.21549 15.0428 4.2745C14.9133 3.31134 14.6431 2.55133 14.0465 1.95479C13.45 1.35825 12.6899 1.08802 11.7268 0.958531C10.7858 0.832018 9.5798 0.832025 8.04087 0.832031H7.96434ZM2.6658 2.6619C3.04557 2.28212 3.55938 2.0642 4.41166 1.94961C5.27831 1.83309 6.41712 1.83203 8.0026 1.83203C9.58807 1.83203 10.7269 1.83309 11.5935 1.94961C12.4458 2.0642 12.9596 2.28212 13.3394 2.6619C13.7192 3.04166 13.9371 3.55548 14.0517 4.40775C14.1682 5.2744 14.1693 6.41322 14.1693 7.9987C14.1693 9.58416 14.1682 10.723 14.0517 11.5896C13.9371 12.4419 13.7192 12.9557 13.3394 13.3355C12.9596 13.7153 12.4458 13.9332 11.5935 14.0478C10.7269 14.1643 9.58807 14.1654 8.0026 14.1654C6.41712 14.1654 5.27831 14.1643 4.41166 14.0478C3.55938 13.9332 3.04557 13.7153 2.6658 13.3355C2.28603 12.9557 2.0681 12.4419 1.95352 11.5896C1.837 10.723 1.83594 9.58416 1.83594 7.9987C1.83594 6.41322 1.837 5.2744 1.95352 4.40775C2.0681 3.55548 2.28603 3.04166 2.6658 2.6619Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_4145_46923'>
          <rect width='16' height='16' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Articles;
