import React from 'react';

interface CustomIconProps {
  filled?: boolean;
}

const YoutubePlayVideoIcon: React.FC<CustomIconProps> = () => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipRule='url(#clip0_4145_46891)'>
      <path
        d='M14.4165 13.2506H12.5C12.3946 12.8897 12.175 12.5728 11.8742 12.3474C11.5734 12.1219 11.2077 12 10.8317 12C10.4558 12 10.0901 12.1219 9.78928 12.3474C9.4885 12.5728 9.26892 12.8897 9.1635 13.2506H1C0.867392 13.2506 0.740215 13.3033 0.646447 13.397C0.552678 13.4908 0.5 13.618 0.5 13.7506C0.5 13.8832 0.552678 14.0104 0.646447 14.1041C0.740215 14.1979 0.867392 14.2506 1 14.2506H9.165C9.27085 14.6108 9.49051 14.9271 9.79109 15.152C10.0917 15.377 10.457 15.4986 10.8325 15.4986C11.208 15.4986 11.5733 15.377 11.8739 15.152C12.1745 14.9271 12.3941 14.6108 12.5 14.2506H14.415C14.5476 14.2506 14.6748 14.1979 14.7686 14.1041C14.8623 14.0104 14.915 13.8832 14.915 13.7506C14.915 13.618 14.8623 13.4908 14.7686 13.397C14.6748 13.3033 14.5476 13.2506 14.415 13.2506H14.4165ZM10.8335 14.5006C10.6852 14.5006 10.5402 14.4566 10.4168 14.3742C10.2935 14.2918 10.1974 14.1746 10.1406 14.0376C10.0838 13.9005 10.069 13.7497 10.0979 13.6043C10.1268 13.4588 10.1983 13.3251 10.3032 13.2202C10.4081 13.1154 10.5417 13.0439 10.6872 13.015C10.8327 12.986 10.9835 13.0009 11.1205 13.0577C11.2576 13.1144 11.3747 13.2106 11.4571 13.3339C11.5395 13.4572 11.5835 13.6022 11.5835 13.7506C11.5835 13.9495 11.5045 14.1402 11.3638 14.2809C11.2232 14.4216 11.0324 14.5006 10.8335 14.5006Z'
        fill='white'
      />
      <path
        d='M13.93 0.5H2.07C1.65373 0.500397 1.25463 0.665935 0.960281 0.960281C0.665935 1.25463 0.500397 1.65373 0.5 2.07V9.93C0.500397 10.3463 0.665935 10.7454 0.960281 11.0397C1.25463 11.3341 1.65373 11.4996 2.07 11.5H13.93C14.3463 11.4996 14.7454 11.3341 15.0397 11.0397C15.3341 10.7454 15.4996 10.3463 15.5 9.93V2.07C15.4996 1.65373 15.3341 1.25463 15.0397 0.960281C14.7454 0.665935 14.3463 0.500397 13.93 0.5ZM14.5 9.93C14.4999 10.0811 14.4398 10.226 14.3329 10.3329C14.226 10.4398 14.0811 10.4999 13.93 10.5H2.07C1.91887 10.4999 1.77396 10.4398 1.6671 10.3329C1.56023 10.226 1.50013 10.0811 1.5 9.93V2.07C1.50013 1.91887 1.56023 1.77396 1.6671 1.6671C1.77396 1.56023 1.91887 1.50013 2.07 1.5H13.93C14.0811 1.50013 14.226 1.56023 14.3329 1.6671C14.4398 1.77396 14.4999 1.91887 14.5 2.07V9.93Z'
        fill='white'
      />
      <path
        d='M10.765 5.576L6.765 3.076C6.68931 3.0287 6.60235 3.00251 6.51312 3.00017C6.4239 2.99783 6.33568 3.01941 6.25762 3.06268C6.17955 3.10595 6.11449 3.16932 6.06919 3.24622C6.02389 3.32312 6 3.41075 6 3.5V8.5C6 8.58925 6.02389 8.67688 6.06919 8.75378C6.11449 8.83068 6.17955 8.89406 6.25762 8.93732C6.33568 8.98059 6.4239 9.00217 6.51312 8.99983C6.60235 8.99749 6.68931 8.9713 6.765 8.924L10.765 6.424C10.8374 6.37916 10.8971 6.31659 10.9385 6.24221C10.9799 6.16784 11.0016 6.08412 11.0016 5.999C11.0016 5.91388 10.9799 5.83016 10.9385 5.75579C10.8971 5.68141 10.8374 5.61884 10.765 5.574V5.576ZM7 7.6V4.4L9.5565 6L7 7.6Z'
        fill='white'
      />
    </g>
    <defs>
      <clipPath id='clip0_4145_46891'>
        <rect width='16' height='16' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export default YoutubePlayVideoIcon;
