import React from 'react';

interface CustomIconProps extends React.SVGProps<SVGSVGElement> {
  width?: number;
  height?: number;
  fill?: string;
}

const FrameworkIcon: React.FC<CustomIconProps> = () => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipRule='url(#clip0_4145_46843)'>
        <path
          d='M19.4141 0H17.0703C16.7467 0 16.4844 0.262344 16.4844 0.585938V1.17188H11.657C11.4152 0.489922 10.7639 0 10 0C9.23613 0 8.58484 0.489922 8.34297 1.17188H3.51562V0.585938C3.51562 0.262344 3.25328 0 2.92969 0H0.585938C0.262344 0 0 0.262344 0 0.585938V2.92969C0 3.25328 0.262344 3.51562 0.585938 3.51562H2.92969C3.25328 3.51562 3.51562 3.25328 3.51562 2.92969V2.33594H5.95223C3.83832 3.67477 2.55332 5.93652 2.39875 8.33758C1.70852 8.57492 1.21094 9.23023 1.21094 10C1.21094 10.9693 1.99949 11.7578 2.96875 11.7578C3.93801 11.7578 4.72656 10.9693 4.72656 10C4.72656 9.24297 4.24551 8.59617 3.57305 8.34922C3.78109 5.58957 5.76828 3.21273 8.42562 2.53836C8.71367 3.11695 9.31098 3.51562 10 3.51562C10.689 3.51562 11.2863 3.11695 11.5744 2.5384C14.2318 3.21277 16.2189 5.58961 16.427 8.34926C15.7545 8.59617 15.2734 9.24297 15.2734 10C15.2734 10.9693 16.062 11.7578 17.0312 11.7578C18.0005 11.7578 18.7891 10.9693 18.7891 10C18.7891 9.23023 18.2915 8.57492 17.6012 8.33758C17.4464 5.93172 16.1575 3.67996 14.0478 2.34375H16.4844V2.92969C16.4844 3.25328 16.7467 3.51562 17.0703 3.51562H19.4141C19.7377 3.51562 20 3.25328 20 2.92969V0.585938C20 0.262344 19.7377 0 19.4141 0ZM2.34375 2.34375H1.17188V1.17188H2.34375V2.34375ZM2.96875 10.5859C2.64566 10.5859 2.38281 10.3231 2.38281 10C2.38281 9.67691 2.64566 9.41406 2.96875 9.41406C3.29184 9.41406 3.55469 9.67691 3.55469 10C3.55469 10.3231 3.29184 10.5859 2.96875 10.5859ZM10 2.34375C9.67691 2.34375 9.41406 2.0809 9.41406 1.75781C9.41406 1.43473 9.67691 1.17188 10 1.17188C10.3231 1.17188 10.5859 1.43473 10.5859 1.75781C10.5859 2.0809 10.3231 2.34375 10 2.34375ZM17.0312 9.41406C17.3543 9.41406 17.6172 9.67691 17.6172 10C17.6172 10.3231 17.3543 10.5859 17.0312 10.5859C16.7082 10.5859 16.4453 10.3231 16.4453 10C16.4453 9.67691 16.7082 9.41406 17.0312 9.41406ZM18.8281 2.34375H17.6562V1.17188H18.8281V2.34375Z'
          fill='#737373'
        />
        <path
          d='M15.175 12.0183L10.4875 4.9871C10.4873 4.98686 10.4871 4.98663 10.487 4.98635C10.4853 4.98385 10.4835 4.98147 10.4818 4.97901C10.4737 4.96733 10.4652 4.95608 10.4564 4.9451C10.2095 4.63831 9.73933 4.65901 9.51824 4.97901C9.51652 4.98147 9.51468 4.98385 9.513 4.98635C9.51285 4.98663 9.51265 4.98682 9.5125 4.9871L4.825 12.0183C4.67003 12.2507 4.7007 12.5602 4.8982 12.7577C6.06722 13.9267 6.81129 15.4548 7.01398 17.0898C6.923 17.1633 6.83515 17.2421 6.75187 17.3273C6.20152 17.8903 5.89843 18.6312 5.89843 19.4137C5.89843 19.7373 6.16078 19.9996 6.48437 19.9996H13.5156C13.8392 19.9996 14.1016 19.7373 14.1016 19.4137C14.1016 18.6312 13.7985 17.8903 13.2482 17.3273C13.1648 17.242 13.077 17.1633 12.986 17.0897C13.1887 15.4547 13.9327 13.9266 15.1018 12.7577C15.2993 12.5602 15.33 12.2507 15.175 12.0183ZM10 11.7574C10.3231 11.7574 10.5859 12.0203 10.5859 12.3433C10.5859 12.6664 10.3231 12.9293 10 12.9293C9.67691 12.9293 9.41406 12.6664 9.41406 12.3433C9.41406 12.0203 9.67691 11.7574 10 11.7574ZM7.17023 18.8277C7.41402 18.1311 8.07488 17.6168 8.82812 17.6168H11.1719C11.9251 17.6168 12.586 18.1311 12.8298 18.8277H7.17023ZM11.8846 16.5341C11.6541 16.4753 11.4152 16.4449 11.1719 16.4449H8.82812C8.5848 16.4449 8.34589 16.4753 8.11543 16.5341C7.84121 14.9549 7.12906 13.4786 6.05878 12.2803L9.41406 7.24733V10.6864C8.73207 10.9282 8.24218 11.5795 8.24218 12.3434C8.24218 13.3126 9.03074 14.1012 10 14.1012C10.9693 14.1012 11.7578 13.3126 11.7578 12.3434C11.7578 11.5795 11.2679 10.9282 10.5859 10.6864V7.24733L13.9412 12.2803C12.8709 13.4785 12.1587 14.9548 11.8846 16.5341Z'
          fill='#737373'
        />
      </g>
      <defs>
        <clipPath id='clip0_4145_46843'>
          <rect width='20' height='20' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FrameworkIcon;
