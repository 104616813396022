import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import Image from 'next/image';
import React from 'react';

import Headings from '../landingPage/heading';
import { ShouldRender } from '../../shared/basic/ShouldRender';
import { IAuthorDetails } from '../../../utils/interfaces/landingPage';

interface BookDescriptionProps {
  data: IAuthorDetails;
}

const AuthorDetailsSection: React.FC<BookDescriptionProps> = ({ data }) => {
  const screens = useBreakpoint();
  return (
    <div className='container mx-auto'>
      <Headings heading='MEET THE AUTHOR' />
      <div
        style={{
          border: '1px solid #FFFFFF24',
          background: screens.md
            ? 'radial-gradient(67.49% 179.15% at 50% 50.31%, #0F0F0F 0%, #2D2D2D 100%)'
            : '#181818',
        }}
        className='flex flex-col p-6  rounded-lg'
      >
        <div className=''>
          <div className='flex flex-row gap-x-5 md:gap-x-7 lg:gap-x-10 h-full'>
            <div className='flex flex-col items-center '>
              <div className='flex items-center h-full md:min-w-[136px]'>
                <ShouldRender check={data?.authorImage}>
                  <Image
                    src={data?.authorImage}
                    alt={data?.authorName as string}
                    width={136}
                    height={191}
                    className='rounded-lg'
                  />
                </ShouldRender>
              </div>
            </div>
            <div className='flex flex-col  '>
              <span className='flex flex-col'>
                <div className='text-base md:text-2xl font-medium'>
                  {data?.authorName}
                </div>

                <span>
                  <span className='inline-flex items-center'>
                    <span className='translate-y-1 pr-1 md:pr-2'>
                      <ShouldRender check={data?.authorName}>
                        <Image
                          alt={data?.authorName || 'company_icon'}
                          src={data?.companyIcon as string}
                          width={16}
                          height={16}
                          className=''
                        />
                      </ShouldRender>
                    </span>
                  </span>
                  <span className='text-xs lg:text-sm'>
                    {' '}
                    {data?.authorDesignation}
                  </span>
                </span>
              </span>
              <ShouldRender check={screens.md}>
                <div className='flex flex-col mt-3 '>
                  <div className=' list-disc'>
                    <ul className='list-disc flex flex-col text-sm gap-y-1 pl-4'>
                      {data?.authorDescription?.map((contentItem, index) => (
                        <li key={index}>{contentItem}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              </ShouldRender>
            </div>
          </div>
        </div>
        <ShouldRender check={!screens.md}>
          <div className='flex flex-col mt-6'>
            <div className=' list-disc'>
              <ul className='list-disc flex flex-col text-sm gap-y-1 pl-4'>
                {data?.authorDescription?.map((contentItem, index) => (
                  <li key={index}>{contentItem}</li>
                ))}
              </ul>
            </div>
          </div>
        </ShouldRender>
      </div>
    </div>
  );
};

export default AuthorDetailsSection;
