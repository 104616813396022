import HeadingPointerIcon from '../../../../../assets/Icons/HeadingPointerIcon';

interface ContentProps {
  heading: string;
}

const Headings: React.FC<ContentProps> = ({ heading }) => {
  return (
    <div className='flex gap-x-3 mb-5 items-center'>
      <HeadingPointerIcon />
      <h2 className='font-bold text-lg uppercase'>{heading}</h2>
    </div>
  );
};

export default Headings;
