import React from 'react';

interface CustomIconProps {
  filled?: boolean;
  color?: string;
}

const HeadingPointerIcon: React.FC<CustomIconProps> = () => (
  <svg
    width='19'
    height='18'
    viewBox='0 0 19 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <rect
      width='3.67226'
      height='16.7594'
      rx='1.83613'
      transform='matrix(0.945856 -0.324586 -0.0159147 0.999873 7.61719 1.19141)'
      fill='#7B7B7B'
    />
    <rect
      width='3.67226'
      height='16.7594'
      rx='1.83613'
      transform='matrix(0.340685 0.940178 -0.999999 0.00115572 16.7578 7.17969)'
      fill='#5B5B5B'
    />
    <rect
      width='3.67226'
      height='16.7594'
      rx='1.83613'
      transform='matrix(0.475659 -0.87963 0.665821 0.746111 3 4.41016)'
      fill='#373737'
    />
    <rect
      width='3.67226'
      height='16.7594'
      rx='1.83613'
      transform='matrix(0.875941 0.482418 -0.751216 0.660056 13.5938 3.17969)'
      fill='#171717'
    />
  </svg>
);

export default HeadingPointerIcon;
