import { FC } from 'react';

interface KeyValuePair {
  name: string;
  value: string;
}

// Define the types for `iconMap` and `formatMap`
interface KeyValueTagProps extends KeyValuePair {
  iconMap: Record<string, JSX.Element>;
  formatMap: Record<string, (value: string) => string>;
  gap?: number;
}

const KeyValueTag: FC<KeyValueTagProps> = ({
  name,
  value,
  iconMap,
  formatMap,
  gap,
}) => {
  const icon = iconMap[name];
  const formattedString = formatMap[name] ? formatMap[name]?.(value) : value;

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {icon && <span style={{ marginRight: `${gap ?? 8}px` }}>{icon}</span>}
      <span className='font-medium text-xs md:text-sm'>
        {value?.trim()?.length > 0 ? value : formattedString}
      </span>
    </div>
  );
};

export default KeyValueTag;
