import { UserOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import React from 'react';

import Headings from '../landingPage/heading';
import { getUserInitials } from '../../ChatBox/Message';
import { ShouldRender } from '../../shared/basic/ShouldRender';
import KeyValueTag from '../../shared/keyPoints';
import Articles from '../../../../assets/Icons/Articles';
import CertificateComplete2 from '../../../../assets/Icons/CertificateComplete2';
import ExcessesIcon from '../../../../assets/Icons/ExcessesIcon';
import FileDownload from '../../../../assets/Icons/FileDownload';
import InfinityIcon from '../../../../assets/Icons/InfinityIcon';
import YoutubePlayVideoIcon from '../../../../assets/Icons/YoutubePlayVideoIcon';
import { IRecommendedAndOverViewSection } from '../../../utils/interfaces/landingPage';

interface RecommendedAndOverViewSectionProps {
  recommendedAndOverViewSectionData: IRecommendedAndOverViewSection;
}

const formatMap = {
  onDemandVideo: (value: string) => `${value} hours on-demand video`,
  exercises: (value: string) => `${value} Exercises`,
  articles: (value: string) => ` ${value} Articles`,
  downloadableResources: (value: string) => `${value} Downloadable Resources`,
  fullLifetimeAccess: () => `Full Lifetime Access`,
  certificate: () => `Certificate of completion`,
};

const iconMap = {
  onDemandVideo: (
    <div className='bg-[#232323] p-3 md:p-2 rounded-full'>
      <YoutubePlayVideoIcon />
    </div>
  ),
  certificate: (
    <div className='bg-[#232323] p-3 md:p-2 rounded-full'>
      <CertificateComplete2 />
    </div>
  ),
  exercises: (
    <div className='bg-[#232323] p-3 md:p-2 rounded-full'>
      {' '}
      <ExcessesIcon />
    </div>
  ),
  articles: (
    <div className='bg-[#232323] p-3 md:p-2 rounded-full'>
      <Articles />
    </div>
  ),
  downloadableResources: (
    <div className='bg-[#232323] p-3 md:p-2 rounded-full'>
      <FileDownload />
    </div>
  ),
  fullLifetimeAccess: (
    <div className='bg-[#232323] p-3 md:p-2 rounded-full'>
      <InfinityIcon />
    </div>
  ),
};

const RecommendedAndOverViewSection: React.FC<
  RecommendedAndOverViewSectionProps
> = ({ recommendedAndOverViewSectionData }) => {
  const screens = useBreakpoint();
  return (
    <div className='flex flex-col '>
      <div
        style={{
          background: screens.md
            ? 'radial-gradient(67.49% 179.15% at 50% 50.31%, #0F0F0F 0%, #2D2D2D 100%)'
            : '#181818',
          border: '1px solid #FFFFFF24',
        }}
        className=' rounded-lg p-5 flex flex-col gap-y-5 mb-[56px]'
      >
        <span
          className='text-xs px-2 py-1 w-min whitespace-nowrap'
          style={{
            background:
              'linear-gradient(90deg, #166FF6 0%, #5E9DC0 38%, #0D9080 100%)',
            borderRadius: '4px',
          }}
        >
          Recommended By
        </span>
        <div className='flex gap-y-3 flex-col'>
          <div className='flex gap-x-3.5 items-center'>
            <Avatar
              size={25}
              alt='profile-picture'
              style={{ fontSize: '16px' }}
              src={recommendedAndOverViewSectionData?.reviewerPhoto}
            >
              {!recommendedAndOverViewSectionData?.reviewerPhoto &&
                (recommendedAndOverViewSectionData?.reviewerName ? (
                  getUserInitials(
                    recommendedAndOverViewSectionData?.reviewerName
                  )
                ) : (
                  <UserOutlined />
                ))}
            </Avatar>

            <span className='text-sm font-semibold'>
              {recommendedAndOverViewSectionData?.reviewerName}
            </span>
          </div>
          <span className='text-sm'>
            {' '}
            {recommendedAndOverViewSectionData?.reviewerDescription}
          </span>
        </div>
      </div>
      <ShouldRender check={recommendedAndOverViewSectionData?.bookIncludes}>
        <Headings heading='This Book includes' />

        <div className='grid md:grid-cols-2 gap-x-10 gap-y-4 md:gap-y-6  rounded-lg '>
          {recommendedAndOverViewSectionData?.bookIncludes?.map(
            (includes, index) => {
              return (
                <KeyValueTag
                  key={index}
                  name={includes.name}
                  value={includes.value ?? ''}
                  iconMap={iconMap}
                  formatMap={formatMap}
                  gap={15}
                />
              );
            }
          )}
        </div>
      </ShouldRender>

      {/* <Headings heading='Overview' />
      <div
        style={{
          border: '1px solid #FFFFFF24',
          background: screens.md
            ? 'radial-gradient(67.49% 179.15% at 50% 50.31%, #0F0F0F 0%, #2D2D2D 100%)'
            : '#181818',
        }}
        className='flex flex-col gap-y-4  p-6 rounded-lg'
      >
        <span className='whitespace-pre-line text-[#E2E2E2] text-xs lg:text-sm'>
          {recommendedAndOverViewSectionData?.overview}
        </span>
      </div> */}
    </div>
  );
};

export default RecommendedAndOverViewSection;
