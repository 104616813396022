import React from 'react';

interface CustomIconProps extends React.SVGProps<SVGSVGElement> {
  width?: number;
  height?: number;
  fill?: string;
}

const BookIcon: React.FC<CustomIconProps> = ({
  width,
  height,
  fill,
  ...rest
}) => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <path
        d='M17.6557 3.55523V1.21094H13.5841C11.7089 1.21094 10.1188 2.45509 9.59591 4.16127C9.13251 3.78261 8.54089 3.55523 7.89719 3.55523H0V18.793H20V3.55523H17.6557ZM18.8279 4.7273V15.2766H12.1028C11.7329 15.2766 11.3802 15.3517 11.0592 15.4874C11.5927 14.6563 12.5251 14.1045 13.5841 14.1045H17.6557V4.7273H18.8279ZM10.586 5.38107C10.586 3.72795 11.9309 2.383 13.5841 2.383H16.4836V12.9324H13.5841C12.4084 12.9324 11.3448 13.4214 10.586 14.2067V5.38107ZM7.89723 4.7273C8.73354 4.7273 9.41397 5.40772 9.41397 6.24403V15.7463C8.98194 15.4501 8.45947 15.2766 7.89723 15.2766H1.17207V4.7273H7.89723ZM1.17207 16.4487H7.89723C8.61508 16.4487 9.21807 16.95 9.37451 17.6209H1.17207V16.4487ZM10.6255 17.6209C10.782 16.95 11.385 16.4487 12.1028 16.4487H18.828V17.6209H10.6255Z'
        fill='#737373'
      />
      <path
        d='M2.53906 6.30859H8.04704V7.48066H2.53906V6.30859Z'
        fill='#737373'
      />
      <path
        d='M2.53906 8.65234H8.04704V9.82441H2.53906V8.65234Z'
        fill='#737373'
      />
      <path
        d='M2.53906 10.9961H8.04704V12.1682H2.53906V10.9961Z'
        fill='#737373'
      />
    </svg>
  );
};

export default BookIcon;
