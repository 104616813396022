import { Grid } from 'antd';
import { motion } from 'framer-motion';
import Image from 'next/image';
import React, { useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import HTMLFlipBook from 'react-pageflip';

import Headings from '../landingPage/heading';
import { ShouldRender } from '../../shared/basic/ShouldRender';

const { useBreakpoint } = Grid;

interface SneakPeekSectionProps {
  data: string[];
}

const SneakPeekSection: React.FC<SneakPeekSectionProps> = ({ data }) => {
  const screens = useBreakpoint();
  const flipBookRef = useRef<any>(null);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { ref: inViewRef, inView } = useInView({
    threshold: 1, // Adjust the threshold to your needs
    triggerOnce: true, // Trigger action only once when it comes into view
  });

  useEffect(() => {
    if (inView) {
      // Simulate a mouse click on the page when it comes into view
      handlePageChange(2 as number);
    }
  }, [inView]);

  const handlePageChange = (pageIndex: number) => {
    setCurrentPage(pageIndex);
    if (flipBookRef.current) {
      flipBookRef.current.pageFlip().flip(pageIndex);
    }
    if (!isOpen) setIsOpen(true); // Set the book as open when page changes
  };

  // For double pages, group them
  const groupedPages = [];
  for (let i = 0; i < data.length; i += 2) {
    groupedPages.push([i, i + 1]);
  }

  const pages = data.map((src, index) => (
    <div key={index} className='w-1/2 rounded-lg'>
      <div className='relative h-full w-full flex rounded-md overflow-hidden'>
        <ShouldRender check={data[index]}>
          <Image
            src={src as string}
            alt={`Page ${index}`}
            layout='fill'
            objectFit='cover'
            className='rounded-md'
          />
        </ShouldRender>
      </div>
    </div>
  ));

  // const renderCarouselContent = () => {
  //   // For small screens, show one image at a time
  //   return data?.map((src, index) => (
  //     <div
  //       key={`${src}-${index}}`}
  //       className='min-h-[500px] flex justify-center'
  //     >
  //       <div className='bg-readerLightGrayBg py-6 px-5 w-full rounded-md'>
  //         <div
  //           ref={pageRef}
  //           className='relative min-h-[480px] w-full flex rounded-md overflow-hidden'
  //         >
  //           <ShouldRender check={src}>
  //             <Image
  //               src={src}
  //               alt={`Slide ${index}`}
  //               layout='fill'
  //               // objectFit='cover'

  //               className='rounded-lg'
  //             />
  //           </ShouldRender>
  //         </div>
  //       </div>
  //     </div>
  //   ));
  // };

  return (
    <div className='container mx-auto'>
      <Headings heading='TAKE A SNEAK PEEK' />{' '}
      {/* <ShouldRender check={screens.md}> */}
      <div
        style={{ position: 'relative' }}
        ref={inViewRef}
        className='customCarousel bg-readerLightGrayBg pt-6 pb-8 md:pb-10 flex px-4 md:px-10 rounded-lg overflow-hidden'
      >
        <HTMLFlipBook
          ref={flipBookRef}
          width={screens.xs ? 150 : 250}
          height={screens.xs ? 250 : 350}
          size='stretch'
          maxShadowOpacity={0.5}
          mobileScrollSupport={true}
          showCover={false}
          drawShadow={true}
          usePortrait={screens.xs}
          flippingTime={1000}
          onFlip={(e: any) => {
            handlePageChange(e.data);
          }}
        >
          {pages}
        </HTMLFlipBook>

        <div
          style={{
            position: 'absolute',
            bottom: 15,
            left: 0,
            width: '100%',
            textAlign: 'center',
          }}
        >
          <div
            style={{ display: 'flex', justifyContent: 'center', gap: '5px' }}
          >
            {groupedPages.map((pagePair, index) => (
              <motion.div
                key={index}
                onClick={() => handlePageChange(pagePair[0] as number)}
                initial={{
                  width:
                    currentPage === pagePair[0] || currentPage === pagePair[1]
                      ? 30
                      : 10,
                }}
                animate={{
                  width:
                    currentPage === pagePair[0] || currentPage === pagePair[1]
                      ? 30
                      : 10,
                }}
                transition={{ duration: 0.3, ease: 'easeInOut' }}
                style={{
                  background:
                    currentPage === pagePair[0] || currentPage === pagePair[1]
                      ? 'white'
                      : 'grey',
                  borderRadius: '5px',
                  height: '5px',
                  cursor: 'pointer',
                }}
              />
            ))}
          </div>
        </div>
      </div>
      {/* </ShouldRender>
      <ShouldRender check={!screens.md}>
        <Carousel className='customCarousel pt-5'>
          {renderCarouselContent()}
        </Carousel>
      </ShouldRender> */}
    </div>
  );
};

export default SneakPeekSection;
